/* Import the Urbanist and Roc Grotesk font families locally */

/* Urbanist font-family */
@font-face {
  font-family: "Urbanist";
  src: url("/Font/Urbanist-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Urbanist';
  src: url('/path/to/fonts/Urbanist-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

/* Roc Grotesk font-family */
@font-face {
  font-family: "Roc Grotesk";
  src: url("/Font/Fontspring-DEMO-rocgrotesk-medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'Roc Grotesk';
  src: url('/path/to/fonts/RocGrotesk-Bold.woff2') format('woff2'),
       url('/path/to/fonts/RocGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
} */

/* Apply Urbanist font family to the body */
body {
  margin: 0;
  font-family: "Urbanist";
}

/* Apply Roc Grotesk font family to headers (h1-h6) */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roc Grotesk";
}

/* Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;
